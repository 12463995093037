.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: calc(13px + 2vmin);
}

h2 {
  font-size: calc(11px + 2vmin);
}

a {
  color: #61dafb;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  border-bottom: solid 1px;
  padding: 0rem 0rem 0rem 0rem;
}
ul.header li a {
  color: #FFF;
  text-shadow: 0em 0em 1em white;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

ul.knowhow_list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.imprint {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  margin-top: 1em;
}

.content_container {
  max-width: 100%;
}

.content {
  padding: 0em 1em 0em 1em;
}

.active {
  background-color: #3C8599;
}

.description {
  font-size: calc(8px + 2vmin);
}

img {
  max-width: 100%;
}

.markdown {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.markdown_paragraph {
  font-size: calc(7px + 2vmin);
  margin: 1em 0 0 0;
}

.note {
  font-size: calc(3px + 2vmin);
}

ul.subheader {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.language_selector {
  background-color: #282c34;
  border: none;
  border-left: solid white 1px;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: calc(3px + 2vmin);
  font-weight: bold;
}
.language_selector_option {
  background-color: #282c34;
  color: white;
}
tr.donate {
  display: flex;
}
td.donate {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  padding-right: 1em;
}
form.donate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileimg {
  width: 8em;
}

.App-footer {
  font-size: calc(2px + 2vmin);
}
.App-logo {
  height: calc(2px + 3vmin);
  pointer-events: none;
  vertical-align: middle;
}

div.repair_bonus {
  width: 100vw;
  position: relative;
  bottom: 0%;
  left: calc(-50vw + 50%);
}
img.repair_bonus {
  width: 100%;
  display: block;
}

.website_carbon_footer {
  margin: 1em 0 0 0;
}